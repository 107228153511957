import miguStory from '@/assets/images/english/migu_story.png'
import xiechengStory from '@/assets/images/english/xiecheng_story.png'
export const caseDetail = {
  '4hwbbbm251h5ys0jnzrp1gczna': {
    contentItemId: '4hwbbbm251h5ys0jnzrp1gczna',
    name: 'Media & Entertainment',
    introduction: 'Increases GPU resource utilization by 200%+ in R&D scenarios. ',
    content: {
      html:
      `<h1>Background</h1><p>A telco company, which is focus on content service providing for music, video,reading, games and animation digital business in China.</p><p><br /></p><h1>Challenge</h1><p>&gt; Under the existing architecture, due to the uncertainty of business flow,online inference service does not have business scalability.</p><p>&gt; Inference service cluster, GPU comprehensive utilization is low.</p><p>&gt; With service development, the number of GPU resources managed by the AIcenter is increasing. Therefore, the AI cannot centrally manage, schedule, andmonitor GPU resources.</p><p><br /></p><h1>VirtAITech Solutions</h1><p>OrionX resource pooling solution integrates with the customer&#39;s existingAI platform, enabling AI to build a UNIFIED scheduling GPU resource poolinglayer, realizing the unified scheduling, flexible allocation, elastic scalingand other cloud capabilities of GPU resources, and meeting the resourcerequirements of different businesses in different time periods.</p><p><img src="${miguStory}" alt="migu.png"/><br /></p><p><br /></p><h1>Benefits</h1><p>&gt; Improving Utilization: increases GPU resource utilization by 200%+ inR&amp;D scenarios.</p><p>&gt; 400%+ in online inference service.</p><p>&gt; Simplified IT ops: Integrate OrionX to PaaS platform to enable GPUresource management automatically.</p>`
    }
  },
  '4gzdx6s9fqs0w4ntzyh9ca32n3': {
    contentItemId: '4gzdx6s9fqs0w4ntzyh9ca32n3',
    name: 'Internet',
    introduction: 'Automatically allocate and release OrionX vGPU resources,  and reducing maintenance and management cost.',
    content: {
      html: `<h1>Background</h1><p>Customer is the top online travel agency in China, using K8s+Jupyter Notebook environment, and consuming lots of GPU resources for AI research.</p><p><br /></p><h1>Challenge</h1><p>&gt; AI developers occupy 1~2 long-term GPU resources for Jupyter Notebook, not releasing even after finish using GPU, causing low utilization of overall GPU resources. </p><p>&gt; No enough GPU resources for every AI developer. </p><p><br /></p><h1>VirtAITech Solutions</h1><p>OrionX GPU Resource Pooling Solution supports GPU resource overallocation, Jupyter Notebook only applies OrionX vGPU resource, achieving GPU resource sharing and on demand using.</p><p><img src="${xiechengStory}" alt="xiecheng.png"/><br /></p><p><br /></p><h1>Benefits</h1><p>&gt; Improving Utilization and Experience: GPU utilization increasing 3 or 4 times. each AI developer has independent Jupyter Notebook environment, at the same time, reducing cost and maintain massive GPU cluster.</p><p>&gt; Usability: Automatically allocate and release OrionX vGPU resources,  and reducing maintenance and management cost.</p>`
    }
  }
}
